import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import InfiniteScroll from "react-infinite-scroll-component"
import { Container, Row, Col } from 'react-bootstrap'


const Topics = ({ location　}) => {

	const data = useStaticQuery(graphql`
	query {
		allMarkdownRemark(
			sort: { fields: [frontmatter___date], order: DESC }
			limit: 500
		) {
			nodes {
				html
				frontmatter {
					date(formatString: "YYYY.MM.DD")
					title
					tags
					description
				}
			}
		}
	}
	`)

	const copy = data.allMarkdownRemark.nodes

	// 全トピックス
	const allTopics = copy.slice()
	// 全トピックス数
	const topicsNum = data.allMarkdownRemark.nodes.length
	// 表示するトピックス数
	const defaultShowNum = 10
	// 初期表示するトピックス数
	const showNuw = ( topicsNum >= defaultShowNum ) ? defaultShowNum : topicsNum
	// 初期表示するトピックス
	const firstTopics = allTopics.slice(0,　showNuw)
	// 初期表示する分だけ削除
	allTopics.splice(0, showNuw)

	//　未表示トピックス
	const [topicsStock, setTopicsStock] = useState(allTopics)
	// 表示するトピックス
	const [topics, setTopics] = useState(firstTopics)
	const [hasMore, setHasMore] = useState(true)

	const fetchMoreData = () => {
		// 全部表示したらローディング止める
		if ( topics.length >= topicsNum ) {
			setHasMore(false)
			return
		}
			
		setTimeout(() => {
			// sliceする数
			const sliceNum = ( topicsStock.length >= defaultShowNum ) ? defaultShowNum : topicsStock.length
			// 新しく表示するトピックス
			var nextTopics = topicsStock.slice(0, sliceNum)
			nextTopics = topics.concat(nextTopics)
			setTopics(nextTopics)
			// 未表示トピックスの更新
			const nextStock = topicsStock
			nextStock.splice(0, sliceNum)
			setTopicsStock(nextStock)
		}, 500);
	}

	const tagsIcon = {
		news:	"お知らせ",
		media: "メディア",
		xhibition: "展示会"
	}

	return (
    <Layout location={location} title="トピックス" >
      <SEO title="トピックス" description="弊社の最新情報を随所掲載しております。" />

			<div className="page-head">
				<Container expand="lg">
					<h2>トピックス</h2>
					<p>弊社の最新情報を随所掲載しております。</p>
				</Container>
				<div className="bottom-border" />
			</div>

			<InfiniteScroll
				dataLength={topics.length}
				next={fetchMoreData}
				hasMore={hasMore}
				loader={<div className="justify-content-center text-center my-5"><p>Loading...</p></div>}
			>
				{ topics.map( (topic, index) => {
					const tags = topic.frontmatter.tags ? topic.frontmatter.tags[0] : "news"
					const date = topic.frontmatter.date
					const title = topic.frontmatter.title
					const description = topic.frontmatter.description
					const html = topic.html

					return (
						<Container className="topics" key={index}>
							<Row>
								<Col lg="auto">
									<p className={`tag mr-4 ${tags}`}>{tagsIcon[tags]}</p>
									<p className="date"><strong>{date}</strong></p>
								</Col>
								<Col>
									<p><strong>{title}</strong></p>
								</Col>
							</Row>
							<p>{description}</p>
							{  html && <section dangerouslySetInnerHTML={{ __html: html }} /> }
							<hr className="dots-hr" />
						</Container>
					)
				})}
			</InfiniteScroll>
    </Layout>
  )
}

export default Topics

